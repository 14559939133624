import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import styles from './ImageSelector.module.scss';
import { IArticleComponentData, IAttributes } from '../../../utils/interfaces/article';

interface Image {
    name: string;
    url: string;
    attributes: IAttributes | { alt: '', loading: '' };
}

interface ImageSelectorProps {
    data?: {
        value: string | null;
        attributes?: IAttributes;
    };
    isBody?: boolean;
    onChange?: (data: IArticleComponentData) => void;
}

export interface ImageSelectorRef {
    getData: () => IArticleComponentData;
}

const ImageSelector: React.ForwardRefRenderFunction<ImageSelectorRef, ImageSelectorProps> = ({ data, onChange, isBody }, ref) => {
    const [images, setImages] = useState<Image[]>([]);
    const [selectedImage, setSelectedImage] = useState<Image | null>(null);
    const dataRef = useRef<IArticleComponentData>({
        type: 'IMAGE',
        value: "",
        meta: {},
        attributes: {}
    });

    useEffect(() => {
        const generateImageData = () => {
            const sampleImages: Image[] = [
                { name: 'Image 1', url: 'https://dummyimage.com/240x180/faa/333', attributes: { alt: '', loading: 'lazy' } },
                { name: 'Image 2', url: 'https://dummyimage.com/240x180/afa/666', attributes: { alt: '', loading: 'lazy' } },
                { name: 'Image 3', url: 'https://dummyimage.com/240x180/aaf/999', attributes: { alt: '', loading: 'lazy' } },
            ];
            setImages(sampleImages);
        };

        generateImageData();
    }, []);

    useEffect(() => {
        if (data && data.value !== null) {
            const selected = images.find(image => image.url === data.value);
            setSelectedImage(selected || null);
            dataRef.current.value = data.value;
            dataRef.current.attributes = data.attributes || {};
        }
    }, [data, images]);

    useEffect(() => {
        if (onChange) {
            onChange(getData());
        }
    }, [selectedImage, data?.attributes, onChange]);

    const getData = (): IArticleComponentData => {
        return {
            ...dataRef.current,
            value: selectedImage?.url || null
        };
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    const handleImageSelect = (event: React.ChangeEvent<{}>, value: Image | null) => {
        setSelectedImage(value);
        dataRef.current.value = value ? value.url : null;
        dataRef.current.attributes = value ? value.attributes : {};
    };

    const handleSelected = (key: string, value?: string) => {
        if (selectedImage) {
            const updatedValue = value?.length ? value : '';
            const updatedSelectedImage = { ...selectedImage, attributes: { ...selectedImage.attributes, [key]: updatedValue } };
            setSelectedImage(updatedSelectedImage);
            dataRef.current.value = updatedSelectedImage.url;
            dataRef.current.attributes = updatedSelectedImage.attributes;
        }
    };

    return (
        <div className={styles['image-selector-container']}>
            <div className={styles['selected-image']}>
                {selectedImage && <img src={selectedImage.url} alt={selectedImage.attributes?.alt || 'Selected image'} />}
            </div>
            <div className={styles['image-picker']}>
                <div>
                    <Autocomplete
                        options={images}
                        getOptionLabel={(option) => option.name}
                        onChange={handleImageSelect}
                        renderInput={(params) => (
                            <TextField {...params} label="Choose image" />
                        )}
                    />
                </div>
                <div>
                    <TextField
                        label="File name"
                        value={selectedImage?.name || ''}
                        InputProps={{ readOnly: true }}
                    />
                </div>
                <div>
                    <TextField
                        label="URL"
                        value={selectedImage?.url || ''}
                        InputProps={{ readOnly: true }}
                    />
                </div>
                {isBody && (<>
                    <div>
                        <TextField
                            label="Alt-Text"
                            value={selectedImage?.attributes?.alt || ''}
                            onChange={(e) => handleSelected('alt', e.target.value)}
                        />
                    </div>
                    <div>
                        <TextField
                            label="Loading:"
                            value={selectedImage?.attributes?.loading || ''}
                            onChange={(e) => handleSelected('loading', e.target.value)}
                        />
                    </div>
                </>)}
            </div>
        </div>
    );
};

export default forwardRef(ImageSelector);
