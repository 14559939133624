import axiosInstance from '../axiosInstance';
import logger from '../../utils/logger';

interface FileData {
    file: File;
    fileName: string;
    title: string;
    tags: string;
}

interface AssetResponse {
    assets: any[];
    found: number;
}
interface FetchBodyProps {
    searchTerms?: string[];
    requestedLimit?: number;
    continuationToken?: string;
}

interface MultipleDeleteBodyProps {
    assetPublicIds?: string[];
}

export const updateAsset = async (
    fileData: any,
    app_uuid: string,
    asset_public_id: string,
    onUploadProgress: (progressEvent: any) => void
): Promise<any> => {
    const formData = new FormData();
    if (fileData.file !== undefined) {
        formData.append("file", fileData.file);
    }
    formData.append("assetPublicId", asset_public_id);
    formData.append("caption", fileData.caption);
    formData.append("alt", fileData.alt);
    formData.append("tags", fileData.tags);
    formData.append("rotate", fileData.rotate);
    formData.append("mirror", fileData.mirror);
    try {
        const response = await axiosInstance.post(`/asset/update/${app_uuid}`, formData, {
            onUploadProgress,
        });

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to update asset: ${response.statusText}`);
        }
    } catch (error: any) {
        throw new Error(`Error updating asset: ${error.message}`);
    }
};


export const uploadAsset = async (
    fileData: FileData,
    app_uuid: string,
    onUploadProgress: (progressEvent: any) => void
): Promise<any> => {
    const formData = new FormData();
    formData.append("file", fileData.file);
    formData.append("caption", fileData.title);
    formData.append("alt", fileData.fileName);
    formData.append("tags", fileData.tags);
    try {
        const response = await axiosInstance.post(`/asset/upload/${app_uuid}`, formData, {
            onUploadProgress,
        });

        if (response.status === 200) {
            return response;
        } else {
            throw new Error(`Failed to upload asset: ${response.statusText}`);
        }
    } catch (error: any) {
        throw new Error(`Error uploading asset: ${error.message}`);
    }
};

export const fetchAssets = async (app_uuid: string, body?: FetchBodyProps): Promise<AssetResponse> => {
    try {
        const response = await axiosInstance.post(`/asset/${app_uuid}/assetList`, {
            ...body
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Failed to fetch assets: ${response.statusText}`);
        }
    } catch (error: any) {
        if (error.response) {
            throw new Error(`Error fetching assets: ${error.response.data}`);
        } else if (error.request) {
            throw new Error('No response received from server. Please check your connection.');
        } else {
            throw new Error(`Error fetching assets: ${error.message}`);
        }
    }
};

export const duplicateAsset = async (
    uuid: string
): Promise<any> => {
    try {
        const response = await axiosInstance.get(`/asset/duplicate/${uuid}`);

        return response;
    } catch (error: any) {
        let errorMessage = 'An error occurred while duplicating assets.';
        if (error.response) {
            errorMessage = `Error duplicating assets: ${error.response.data}`;
        } else if (error.request) {
            errorMessage = 'No response received from server. Please check your connection.';
        } else {
            errorMessage = `Error duplicating assets: ${error.message}`;
        }
        throw new Error(errorMessage);
    }
}

export const deleteMultipleAssets = async (
    app_uuid: string,
    body?: MultipleDeleteBodyProps
): Promise<any> => {
    try {
        logger('info', 'MULTIPLE_ASSETS_DELETE_REQUEST');
        logger('info', `appUUID: ${app_uuid}, assetList: ${body}`);

        const response = await axiosInstance.post(`/asset/deleteMultiple/${app_uuid}`, {
            ...body,
        });

        return response; 
    } catch (error: any) {
        let errorMessage = 'An error occurred while deleting assets.';
        if (error.response) {
            errorMessage = `Error deleting assets: ${error.response.data}`;
        } else if (error.request) {
            errorMessage = 'No response received from server. Please check your connection.';
        } else {
            errorMessage = `Error deleting assets: ${error.message}`;
        }
        throw new Error(errorMessage);
    }
};

export const deleteSingleAsset = async (app_uuid: string, selectedPublicUUID :string) : Promise<any> => {
    try {
        logger('info', 'SINGLE_ASSETS_DELETE_REQUEST');
        logger('info', `appUUID: ${app_uuid}, assetId: ${selectedPublicUUID}`);
        const idParts = selectedPublicUUID.split('/');
        const assetId = idParts[idParts.length - 1];
        const response = await axiosInstance.delete(`/asset/delete/${app_uuid}/${assetId}`);

        return response; 
    } catch (error: any) {
        let errorMessage = 'An error occurred while deleting assets.';
        if (error.response) {
            errorMessage = `Error deleting assets: ${error.response.data}`;
        } else if (error.request) {
            errorMessage = 'No response received from server. Please check your connection.';
        } else {
            errorMessage = `Error deleting assets: ${error.message}`;
        }
        throw new Error(errorMessage);
    }
}
