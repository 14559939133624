import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from '../../../utils/theme/theme';
import styles from './ArticleRenderer.module.scss';
import { ArticleComponentType } from '../../../utils/constants/constants';

interface ArticleRendererProps {
    components: {
        component: JSX.Element | null;
        key: string;
    }[];
    getComponentRef: (key: string) => React.MutableRefObject<any> | undefined;
    imageSelectorRef: React.MutableRefObject<any>;
    textFieldRef: React.RefObject<HTMLInputElement>;
    updateCount: number;
}

const ArticleRenderer: React.FC<ArticleRendererProps> = ({
    components,
    getComponentRef,
    imageSelectorRef,
    textFieldRef,
    updateCount }) => {
    const [renderedComponents, setRenderedComponents] = useState<JSX.Element[]>([]);

    useEffect(() => {
        const updatedComponents: JSX.Element[] = components
            .map(({ key }) => {
                const ref = getComponentRef(key);
                if (ref && ref.current) {
                    const data = ref.current.getData();

                    switch (data.type) {
                        case ArticleComponentType.TEXT:
                            return (
                                <div
                                    key={key}
                                    dangerouslySetInnerHTML={{ __html: data.value || '' }}
                                />
                            );
                        case ArticleComponentType.VIDEO:
                            return (
                                <div key={key}>
                                    {data.value && (
                                        <video
                                            controls={data.attributes.controls}
                                            autoPlay={data.attributes.autoplay}
                                            loop={data.attributes.loop}
                                            muted={data.attributes.muted}
                                            poster={data.attributes.poster}
                                            src={data.value}
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                </div>
                            );
                        case ArticleComponentType.IMAGE:
                            return (
                                <div key={key}>
                                    {data.value && <img src={data.value} alt={data.attributes?.alt || 'Image'} />}
                                </div>
                            );
                        default:
                            return null;
                    }
                }
                return null;
            })
            .filter((element): element is JSX.Element => element !== null);

        setRenderedComponents(updatedComponents);
    }, [components, getComponentRef, updateCount]);

    return (
        <ThemeProvider theme={lightTheme}>
            <div className={styles['article-renderer']}>
                {
                    textFieldRef?.current?.value && (
                        <div>
                            {textFieldRef.current.value}
                        </div>
                    )
                }

                {
                    imageSelectorRef?.current?.getData().value && (
                        <div>
                            <img
                                src={imageSelectorRef.current.getData().value}
                                alt={imageSelectorRef.current.getData().attributes?.alt || 'Selected Image'}
                            />
                        </div>
                    )
                }

                {renderedComponents.length > 0 &&
                    <div className={styles['selected-components']}>
                        {renderedComponents}
                    </div>
                }
            </div>
        </ThemeProvider>
    );
};

export default ArticleRenderer;
