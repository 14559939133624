import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@mui/material';
import styles from './TagSelector.module.scss';

interface Tag {
    tagId: number;
    tagText: string;
}

interface TagSet {
    label: string;
    tags: Tag[];
    selectedTagId: number | null;
    onSelect: (tagId: number) => void;
}

interface TagSelectorProps {
    tagSets: TagSet[];
    initialSelectedTags?: number[];
}

export interface TagSelectorRef {
    getData: () => { type: string; key: string; data: object; };
}

const TagSelector: React.ForwardRefRenderFunction<TagSelectorRef, TagSelectorProps> = ({ tagSets, initialSelectedTags = [] }, ref) => {
    const [selectedTags, setSelectedTags] = useState<{ [key: number]: number | null }>({});

    const dataRef = useRef<{ type: string; key: string; data: { [key: string]: number | null } }>({
        type: 'tag',
        key: "",
        data: selectedTags
    });

    useEffect(() => {
        const initialSelectedTagsMap: { [key: number]: number | null } = {};
        tagSets.forEach((tagSet, index) => {
            initialSelectedTagsMap[index] = initialSelectedTags[index] !== undefined ? Number(initialSelectedTags[index]) : Number(tagSet.selectedTagId);
        });
        setSelectedTags(initialSelectedTagsMap);
        dataRef.current.data = initialSelectedTagsMap;
    }, [tagSets, initialSelectedTags]);

    const getData = () => {
        return dataRef.current;
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    const handleSelect = (index: number, tagId: number) => {
        setSelectedTags(prevSelectedTags => {
            const updatedSelectedTags = { ...prevSelectedTags, [index]: tagId };
            dataRef.current.data = updatedSelectedTags;
            return updatedSelectedTags;
        });
        tagSets[index].onSelect(tagId);
    };

    return (
        <div>
            {tagSets.map((tagSet, index) => (
                <FormControl component="fieldset" className={styles['tag-selector-form-control']} key={index}>
                    <FormLabel component="legend">{tagSet.label}</FormLabel>
                    <RadioGroup
                        value={selectedTags[index] ?? ""}
                        onChange={(event) => {
                            const selectedId = parseInt(event.target.value, 10);
                            handleSelect(index, selectedId);
                        }}
                        className={styles['tag-selector-radio-group']}
                    >
                        {tagSet.tags.map(tag => (
                            <FormControlLabel
                                key={tag.tagId}
                                value={tag.tagId}
                                control={<Radio />}
                                label={tag.tagText}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            ))}
        </div>
    );
};

export default forwardRef(TagSelector);
