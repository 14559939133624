import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './WYSIWYG.module.scss';
import { IArticleComponentData } from '../../../utils/interfaces/article';

interface WYSIWYGProps {
    data?: {
        value: string | null;
    };
    onChange?: (data: IArticleComponentData) => void;
}

export interface WYSIWYGRef {
    getData: () => IArticleComponentData;
}

const WYSIWYG: React.ForwardRefRenderFunction<WYSIWYGRef, WYSIWYGProps> = ({ data, onChange }, ref) => {
    const [editorContent, setEditorContent] = useState<string>('');
    
    const dataRef = useRef<IArticleComponentData>({
        type: 'TEXT',
        value: '',
        meta: {}
    });

    useEffect(() => {
        if (data && data.value !== null) {
            setEditorContent(data.value);
        }
    }, [data]);

    useEffect(() => {
        if (onChange) {
            const latestData = getData();
            onChange(latestData);
        }
    }, [editorContent, onChange]);

    const getData = () => {
        return {
            ...dataRef.current,
            value: editorContent 
        };
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    const handleChange = (content: string) => {
        setEditorContent(content);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link'],
            ['clean'],
            ['code-block']
        ]
    };

    const formats = [
        'header', 'font',
        'list', 'bullet',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background',
        'align',
        'link',
        'clean',
        'code-block'
    ];

    return (
        <div>
            <ReactQuill
                theme="snow"
                value={editorContent}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                className={styles['quill-editor']}
            />
        </div>
    );
};

export default forwardRef(WYSIWYG);
