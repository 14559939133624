import { v4 as uuidv4 } from 'uuid';
import { bytesToMB, handleGenerateThumbnail } from "../../generic";
import { ASSET_SIZE_LIMIT } from "../../constants/constants";

const handleCheckAssetSize = (file: File) => {
    return Number(bytesToMB(file.size)) > ASSET_SIZE_LIMIT;
};

export const handleFileProcessing = async (
    files: FileList | File[]
): Promise<any[]> => {
    const mappedFiles = Array.from(files);
    const filteredFiles = mappedFiles.filter((file) => file.type.match(/video.*/) || file.type.match(/image.*/));

    const processedFiles: any[] = [];

    for (const file of filteredFiles) {
        const [type] = file.type.split('/');
        if (type === 'video') {
            const video = document.createElement('video');
            video.src = URL.createObjectURL(file);
            video.preload = 'metadata';

            await new Promise<void>((resolve) => {
                video.addEventListener('loadedmetadata', async () => {
                    const duration = video.duration.toFixed(2);
                    const resolution = `${video.videoWidth} x ${video.videoHeight}`;
                    const width = video.videoWidth;
                    const height = video.videoHeight;

                    const thumbnail = await handleGenerateThumbnail(file);

                    const fileData = {
                        uuid: uuidv4(),
                        fileName: file.name,
                        fileSize: bytesToMB(file.size) + ' mb',
                        type: file.type,
                        url: thumbnail,
                        resolution,
                        width,
                        height,
                        duration,
                        title: file.name,
                        alt: file.name,
                        tags: "",
                        errors: handleCheckAssetSize(file),
                        file,
                    };

                    processedFiles.push(fileData);
                    resolve();
                });
            });
        } else {
            const img = document.createElement('img');
            img.src = URL.createObjectURL(file);

            await new Promise<void>((resolve) => {
                img.addEventListener('load', () => {
                    const resolution = `${img.width} x ${img.height}`;
                    const width = img.width;
                    const height = img.height;

                    const fileData = {
                        uuid: uuidv4(),
                        fileName: file.name,
                        fileSize: bytesToMB(file.size) + ' mb',
                        type: file.type,
                        url: img.src,
                        resolution,
                        width,
                        height,
                        duration: 'N/A',
                        title: file.name,
                        alt: file.name,
                        tags: "",
                        errors: handleCheckAssetSize(file),
                        file,
                    };

                    processedFiles.push(fileData);
                    resolve();
                });
            });
        }
    }

    return processedFiles;
};
