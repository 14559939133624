import React, { useState, useEffect } from "react";
import { AddEditArticle, ListArticles } from "../../components/Article";
import { useAppSelector } from "../../utils/hooks";
import AddIcon from "@mui/icons-material/Add";
import {
  CircularProgress,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Checkbox,
  SelectChangeEvent,
  Select,
  OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  ViewModule,
  Search,
  ViewList,
  Cancel,
} from "@mui/icons-material";

import styles from "./Articles.module.scss";

import {
  DEFAULT_ITEMS_TO_LOAD,
  NEW_ITEMS_TO_LOAD,
  ARTICLE_FILTER_OPTIONS,
  DEFAULT_DATE_FORMAT,
} from "../../utils/constants/constants";

import {
  IArticleResponse,
  ArticleFilterBy,
} from "../../utils/interfaces/article";
import {
  deleteArticle,
  listArticle,
  deleteMultiArticle,
  filterArticle,
} from "../../utils/api/article";
import { SeverityType } from "../../utils/constants/constants";
import CustomModal from "../../components/CustomModal/CustomModal";
import Snackbar from "../../components/Snackbar/Snackbar";
import { formatDate, toTitleCase } from "../../utils/generic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Articles: React.FC = () => {
  const { selectedEdition } = useAppSelector((state: any) => state.edition);
  const [showAddEditArticle, setShowAddEditArticle] = useState(false);
  const [articleUpdater, setArticleUpdater] = useState<boolean>(true);

  const { selectedApplication } = useAppSelector(
    (state: any) => state.application
  );
  const userPermissions = selectedApplication.permissions;
  const filterOptions = ARTICLE_FILTER_OPTIONS;

  // Article Editing
  const [currentArticleUuid, setCurrentArticleUuid] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchedArticles, setSearchedArticles] = useState<IArticleResponse[]>(
    []
  );
  const [visibleArticlesCount, setVisibleArticlesCount] = useState(
    DEFAULT_ITEMS_TO_LOAD
  );
  const [selectedArticles, setSelectedArticles] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<"grid" | "list">("grid");
  const [selectedFilters, setSelectedFilters] = useState<string[][]>([]);
  const [sortBy, setSortOrderBy] = useState<"updatedAt" | "title">("updatedAt");
  const [sortMode, setSortMode] = useState("desc");
  const [articles, setArticles] = useState<IArticleResponse[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedMultiDelete, setSelectedMultiDelete] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterTitle, setFilterTitle] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterValue, setFilterValue] = useState<any>(null);
  const [filterErrorMessage, setFilterErrorMessage] = useState("");
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    severity: SeverityType.Success | SeverityType.Error;
  }>({
    open: false,
    message: "",
    severity: SeverityType.Success,
  });

  useEffect(() => {
    if (searchQuery.length === 0 && selectedFilters.length === 0) {
      setIsLoading(false);
      setSearchedArticles([]);
      return;
    }
    setIsLoading(true);
    let timeoutId = setTimeout(() => {
      handleSearchArticle();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchQuery, selectedFilters]);

  const handleSearchArticle = async () => {
    let filteredArticles: IArticleResponse[] = [];
    filteredArticles = (await fetchData()) as IArticleResponse[];
    setSearchedArticles(filteredArticles);
    setIsLoading(false);
  };

  const fetchData = async () => {
    let selectedFormatFilters: ArticleFilterBy[] = [];
    selectedFilters.forEach((item) => {
      const key = item[0];
      const val = item[1].substring(item[1].indexOf(":") + 1).trim();
      const filterObject = {
        [`${key}`]: val.toLowerCase(),
      };

      selectedFormatFilters.push(filterObject);
    });

    try {
      const data = await filterArticle(selectedEdition.uuid, {
        filterBy: selectedFormatFilters,
        filterTerm: searchQuery,
        sortBy: sortBy,
        sortMode: sortMode,
      });
      if (data?.articles) {
        return data.articles;
      }
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!selectedEdition) {
      hideSnackbar(false, "No edition selected", SeverityType.Error);
      setIsLoading(false);
      return;
    }
    fetchArticles();
  }, [selectedEdition]);

  const fetchArticles = async () => {
    const data = await listArticle(selectedEdition.uuid);
    if (data?.articles) {
      setArticles([...data.articles]);
    }
    setIsLoading(false);
  };

  const handleCreateArticle = () => {
    setCurrentArticleUuid(null);
    setShowAddEditArticle(true);
  };

  const handleEditArticle = (uuid: string) => {
    setCurrentArticleUuid(uuid);
    setShowAddEditArticle(true);
  };

  const handleCancelAddEditArticle = () => {
    setCurrentArticleUuid(null);
    setShowAddEditArticle(false);
  };

  const handleArticleChange = () => {
    setArticleUpdater(!articleUpdater);
    hideSnackbar(false, "Article Saved Successfully", SeverityType.Success);
  };

  useEffect(() => {
    fetchArticles();
  }, [articleUpdater]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      const count = (prevCount: number) =>
        Math.min(prevCount + NEW_ITEMS_TO_LOAD, articles.length);
      setVisibleArticlesCount(count);
    }
  };
  const handleSelectArticles = (uuid: string) => {
    setSelectedArticles((prevSelected) =>
      prevSelected.includes(uuid)
        ? prevSelected.filter((articleId) => articleId !== uuid)
        : [...prevSelected, uuid]
    );
  };
  const handleSelectAll = () => {
    const displayedArticles =
      articles &&
      searchedArticles &&
      (searchQuery.length > 0 || selectedFilters.length > 0)
        ? searchedArticles
        : articles;

    setSelectedArticles(
      displayedArticles.length === selectedArticles.length
        ? []
        : displayedArticles.map((article) => article.uuid)
    );
  };
  const handleOrderByChange = (
    _event: React.MouseEvent<HTMLElement>,
    newSortMode: string | null
  ) => {
    if (newSortMode !== null) {
      setSortMode(newSortMode);
    }
  };

  const handleSortByChange = (
    event: SelectChangeEvent<"updatedAt" | "title">
  ) => {
    setSortOrderBy(event.target.value as any);
  };

  useEffect(() => {
    const sortedArticles = handleSort();
    if (sortedArticles) {
      if (searchedArticles.length > 0) {
        setSearchedArticles(sortedArticles);
        return;
      }
      setArticles(sortedArticles);
    }
  }, [sortBy, sortMode]);

  const handleSort = (): IArticleResponse[] => {
    const articleList =
      articles &&
      searchedArticles &&
      (searchQuery.length > 0 || selectedFilters.length > 0)
        ? searchedArticles
        : articles;

    if (!articleList || articleList.length === 0) {
      return [];
    }

    const sortedArticles = [...articleList].sort((a, b) => {
      if (sortBy === "updatedAt") {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return sortMode === "asc" ? dateA - dateB : dateB - dateA;
      } else if (sortBy === "title") {
        const titleA = a.currentVersion.title.toLowerCase();
        const titleB = b.currentVersion.title.toLowerCase();
        if (titleA < titleB) return sortMode === "asc" ? -1 : 1;
        if (titleA > titleB) return sortMode === "asc" ? 1 : -1;
        return 0;
      }
      return 0;
    });

    return sortedArticles;
  };

  const handlePredifinedFilterAdd = (filterSelected: any) => {
    setSelectedArticles([]);
    const filterOption = filterSelected;
    if (filterOption.length < 2) return;
    if (filterOption[1].endsWith("...")) {
      setFilterTitle(filterOption[1].slice(0, -3));
      setFilterType(filterOption[0]);
      setOpenFilterModal(true);
    } else {
      const isAlreadyFiltered = selectedFilters.some((subOption) =>
        subOption.every((value, index) => value === filterOption[index])
      );
      if (!isAlreadyFiltered) {
        setSelectedFilters((prevFilters) => [...prevFilters, filterOption]);
      }
    }
  };
  const handleOpenFilterAdd = () => {
    if (!filterType || !filterValue) {
      setFilterErrorMessage("Please enter value...");
      return false;
    }
    const filterOption = [
      filterType,
      toTitleCase(`${filterTitle}`) + `: ${filterValue}`,
    ];
    const isAlreadyFiltered = selectedFilters.some((subOption) =>
      subOption.every((value, index) => value === filterOption[index])
    );
    if (!isAlreadyFiltered) {
      setSelectedFilters((prevFilters) => [...prevFilters, filterOption]);
    }
    setFilterType("");
    setFilterValue(null);
    setFilterTitle("");
    setFilterErrorMessage("");
    setOpenFilterModal(false);
  };

  const handleFilterRemove = (filterIndex: number) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((f, i) => i !== filterIndex)
    );
    setFilterType("");
    setFilterValue(null);
    setFilterTitle("");
    setFilterErrorMessage("");
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const closeFilterModal = () => {
    setFilterType("");
    setFilterValue(null);
    setFilterTitle("");
    setFilterErrorMessage("");
    setOpenFilterModal(false);
  };
  const deleteSingleArticle = async () => {
    setIsLoading(true);
    if (!currentArticleUuid) return;
    const res = await deleteArticle(currentArticleUuid);
    if (res.status === 200) {
      setArticles((prevArticles) =>
        prevArticles.filter((article) => article.uuid !== currentArticleUuid)
      );
      setOpenDeleteModal(false);
      setIsLoading(false);
      hideSnackbar(false, res.data.message, SeverityType.Success);
    } else {
      setOpenDeleteModal(false);
      setIsLoading(false);
      hideSnackbar(false, res.data.error.message, SeverityType.Error);
    }
  };
  const confirmDeleteArticle = (uuid: string) => {
    setCurrentArticleUuid(uuid);
    setOpenDeleteModal(true);
  };
  const deleteMultipleArticle = async () => {
    if (!selectedArticles.length) {
      hideSnackbar(false, "Please select article", SeverityType.Error);
    }
    setIsLoading(true);
    const res = await deleteMultiArticle(selectedArticles);
    if (res.status === 200) {
      setArticles((prevArticles) =>
        prevArticles.filter(
          (article) => !selectedArticles.includes(article.uuid)
        )
      );
      setOpenDeleteModal(false);
      setSelectedMultiDelete(false);
      setIsLoading(false);
      hideSnackbar(false, res.data.message, SeverityType.Success);
    } else {
      setOpenDeleteModal(false);
      setSelectedMultiDelete(false);
      setIsLoading(false);
      hideSnackbar(false, res.data.error.message, SeverityType.Error);
    }
  };
  const confirmDeleteMultipleArticles = () => {
    setSelectedMultiDelete(true);
    setOpenDeleteModal(true);
  };

  const hideSnackbar = (
    hide: boolean = true,
    message: string = "",
    severity: SeverityType.Success | SeverityType.Error = SeverityType.Success
  ) => {
    setSnackbarState({
      open: !hide,
      message: message,
      severity: severity,
    });
  };
  const handleCloseSnackbar = () => {
    hideSnackbar();
  };
  useEffect(() => {
    handleSort();
    handleDisplayArticles();
  }, [searchedArticles, articles]);

  const handleDisplayArticles = () => {
    const articleList =
      articles &&
      searchedArticles &&
      (searchQuery.length > 0 || selectedFilters.length > 0)
        ? searchedArticles
        : articles;

    if (!articleList || articleList.length === 0) {
      return <div className={styles["no-data"]}>No Articles found</div>;
    } else {
      const articleCount = articleList.length
        ? articleList.length
        : visibleArticlesCount;

      return [...articleList].slice(0, articleCount).map((article) => {
        return (
          <ListArticles
            key={article.uuid}
            articleData={article}
            viewMode={viewMode}
            isSelected={selectedArticles.includes(article.uuid)}
            onSelect={handleSelectArticles}
            onEdit={handleEditArticle}
            onDelete={confirmDeleteArticle}
          />
        );
      });
    }
  };

  return (
    <div className={styles["articles"]}>
      <Snackbar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={handleCloseSnackbar}
      />
      {showAddEditArticle && (
        <AddEditArticle
          uuid={currentArticleUuid}
          onClose={handleCancelAddEditArticle}
          onArticleChange={handleArticleChange}
        />
      )}
      <div className={styles["header-bar"]}>
        <div className={styles["top-bar"]}>
          <div className={styles["title"]}>Article Library</div>
          {userPermissions?.includes("ArticleCollections.CreateArticle") && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleCreateArticle}
              endIcon={<AddIcon />}
            >
              New
            </Button>
          )}
        </div>
        {userPermissions?.includes(
          "ArticleCollections.ReadEditionArticles"
        ) && (
          <>
            <div className={styles["search-bar"]}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search term..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />

              <FormControl className={styles["filter-select"]} variant="outlined" style={{ width: "150px" }}>
                <InputLabel id="filter-label">Filtering</InputLabel>
                <Select
                  labelId="filter-label"
                  value="--"
                  onChange={(e) => handlePredifinedFilterAdd(e.target.value)}
                  label="Filtering"
                  input={<OutlinedInput />}
                >
                  <MenuItem value="--">Apply filter</MenuItem>
                  {filterOptions.map((filter, i) => (
                    <MenuItem key={i} value={[filter.key, filter.value]}>
                      {filter.value.endsWith("...")
                        ? filter.value.slice(0, -3)
                        : filter.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className={styles["filters"]}>
                {selectedFilters.map((filter, i) => (
                  <Button
                    key={i}
                    variant="outlined"
                    size="medium"
                    endIcon={<Cancel />}
                    onClick={() => handleFilterRemove(i)}
                    style={{ margin: "5px" }}
                  >
                    {filter[1]}
                  </Button>
                ))}
              </div>
            </div>

            <div className={styles["view-bar"]}>
              <div className={styles["view-type"]}>
                <div>
                  <span>View:</span>
                  <ToggleButtonGroup
                    size="small"
                    value={viewMode}
                    exclusive
                    onChange={(event, newViewMode) => {
                      newViewMode && setViewMode(newViewMode);
                    }}
                  >
                    <ToggleButton value="grid">
                      <ViewModule />
                    </ToggleButton>
                    <ToggleButton value="list">
                      <ViewList />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <span>
                  {articles &&
                  searchedArticles &&
                  (searchQuery.length > 0 || selectedFilters.length > 0)
                    ? searchedArticles.length
                    : articles.length}{" "}
                  Articles
                </span>
              </div>
              <div className={styles["sort-select"]}>
                <FormControl fullWidth>
                  <InputLabel id="sort-label">Sort By</InputLabel>
                  <Select
                    labelId="sort-label"
                    value={sortBy}
                    onChange={handleSortByChange}
                    label="Sort By"
                    className={styles["select-input"]}
                    // this is for testing. once after test will remove
                    // input={<OutlinedInput />}
                  >
                    <MenuItem value="updatedAt">Date</MenuItem>
                    <MenuItem value="title">Title</MenuItem>
                  </Select>
                </FormControl>
                <div className={styles["sort-order"]}>
                  Order:
                  <ToggleButtonGroup
                    value={sortMode}
                    size="small"
                    exclusive
                    onChange={handleOrderByChange}
                    aria-label="sort mode"
                  >
                    <ToggleButton value="asc" aria-label="sort ascending">
                      <ArrowUpward />
                    </ToggleButton>
                    <ToggleButton value="desc" aria-label="sort descending">
                      <ArrowDownward />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <CustomModal
        open={openFilterModal}
        onClose={closeFilterModal}
        onSave={handleOpenFilterAdd}
        title={`Please enter ${filterTitle}`}
        content=""
      >
        <div className={styles["filterModal"]}>
          <div className={styles["filterError"]}>{filterErrorMessage}</div>
          {filterType === "tag" && (
            <TextField
              id="outlined-basic"
              value={filterValue}
              onChange={(e: any) => {
                setFilterValue(e.target.value);
                setFilterErrorMessage("");
              }}
            />
          )}
          {filterType === "language" && <>Add language drop down here (TBD)</>}
          {(filterType === "createdAt" || filterType === "updatedAt") && (
            <>
              <DatePicker
                className={styles["filterByDate"]}
                selected={filterValue}
                onChange={(e: any) => {
                  setFilterValue(formatDate(e, DEFAULT_DATE_FORMAT));
                  setFilterErrorMessage("");
                }}
                dateFormat={DEFAULT_DATE_FORMAT}
                placeholderText="Select a date"
              />
            </>
          )}
        </div>
      </CustomModal>
      <CustomModal
        open={openDeleteModal}
        onClose={closeDeleteModal}
        onSave={
          selectedMultiDelete ? deleteMultipleArticle : deleteSingleArticle
        }
        title={`Delete Article${selectedMultiDelete ? "'s" : ""}`}
        content="Are you certain you wish to delete this article?"
      ></CustomModal>

      <div className={styles["article-view"]} onScroll={handleScroll}>
        {userPermissions?.includes(
          "ArticleCollections.ReadEditionArticles"
        ) && (
          <>
            {isLoading ? (
              <>
                <CircularProgress className={styles["loader"]} />
              </>
            ) : (
              handleDisplayArticles()
            )}
          </>
        )}
      </div>
      {(articles &&
      searchedArticles &&
      (searchQuery.length > 0 || selectedFilters.length > 0)
        ? searchedArticles
        : articles
      ).length > 0 && (
        <>
          <div className={styles["footer-bar"]}>
            {userPermissions?.includes(
              "ArticleCollections.ReadEditionArticles"
            ) && (
              <>
                <div>
                  <div>
                    <Checkbox
                      checked={
                        selectedArticles.length
                          ? searchQuery.length > 0 || selectedFilters.length > 0
                            ? selectedArticles.length ===
                              searchedArticles.length
                            : selectedArticles.length === articles.length
                          : false
                      }
                      onChange={handleSelectAll}
                      color="primary"
                      className={`${styles["select-checkbox"]}`}
                    />
                    <span>
                      {(!(
                        searchQuery.length > 0 || selectedFilters.length > 0
                      ) &&
                        selectedArticles.length < articles.length) ||
                      ((searchQuery.length > 0 || selectedFilters.length > 0) &&
                        selectedArticles.length < searchedArticles.length) ? (
                        <>Select</>
                      ) : (
                        <>Deselect</>
                      )}{" "}
                      all
                    </span>
                  </div>
                  {selectedArticles && selectedArticles.length > 0 && (
                  <div>
                    {selectedArticles.length} of{" "}
                    {articles &&
                    searchedArticles &&
                    (searchQuery.length > 0 || selectedFilters.length > 0)
                      ? searchedArticles.length
                      : articles.length}{" "}
                    items selected
                  </div>
                  )}
                </div>
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<Delete />}
                    disabled={selectedArticles.length === 0}
                    onClick={() => confirmDeleteMultipleArticles()}
                  >
                    Delete
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Articles;
