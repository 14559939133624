import React, {
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  SetStateAction,
} from "react";
import { useAppSelector } from "../../utils/hooks";
import {
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Select,
  OutlinedInput,
  ToggleButtonGroup,
  ToggleButton,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowUpward,
  Delete,
  LocalOffer,
  ViewModule,
  FileUpload,
  Search,
  ViewList,
  Cancel,
} from "@mui/icons-material";
import { bytesToMB, formatDate } from "../../utils/generic";
import {
  ASSET_SIZE_LIMIT,
  ASSET_UPLOAD_INDEX,
  SeverityType,
} from "../../utils/constants/constants";
import { IAssetItem } from "../../utils/interfaces/assets";
import AssetDataRenderer from "../../components/AssetComponents/AssetDataRenderer";
import styles from "./Assets.module.scss";
import customMuiLayer from "../../styles/customMuiLayer.module.scss";
import { AssetUploadModal } from "./AssetUploadModal";
import {
  fetchAssets,
  deleteSingleAsset,
  deleteMultipleAssets,
  duplicateAsset,
} from "../../utils/api/assets";
import { handleFileProcessing } from "../../utils/helpers/assets/assetHelpers";
import Snackbar from "../../components/Snackbar/Snackbar";
import CustomModal from "../../components/CustomModal";
import {
  DEFAULT_ITEMS_TO_LOAD,
  NEW_ITEMS_TO_LOAD,
} from "../../utils/constants/constants";

const filterOptions = ["Filter 1", "Filter 2", "Filter 3"];

const Assets: React.FC = () => {
  const { selectedApplication } = useAppSelector((state) => state.application);
  const userPermissions = selectedApplication.permissions;
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    message: string;
    severity: SeverityType.Success | SeverityType.Error;
  }>({
    open: false,
    message: "",
    severity: SeverityType.Success,
  });
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [viewMode, setViewMode] = useState<"grid" | "list">("grid");
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedPublicId, setSelectedPublicId] = useState("");
  const [sampleAssets, setSampleAssets] = useState<IAssetItem[]>([]);
  const [deletionFailed, setDeletionFailed] = useState(false);
  const [sortOrderBy, setSortOrderBy] = useState<"date" | "title">("date");
  const [sortMode, setSortMode] = useState("up");
  const [openAssetModal, setOpenAssetModal] = useState<boolean>(false);
  const [searchedAssets, setSearchedAssets] = useState<IAssetItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assetData, setAssetData] = useState<any>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedMultiDelete, setSelectedMultiDelete] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [visibleAssetsCount, setVisibleAssetsCount] = useState(
    DEFAULT_ITEMS_TO_LOAD
  );

  const handleUploadAssetButtonClick = async (
    e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement> | any
  ) => {
    let files = e.target.files;
    if (files) {
      if (
        files.length === 1 &&
        Number(bytesToMB(files[0].size)) > ASSET_SIZE_LIMIT
      ) {
        hideSnackbar(
          false,
          `File Size > ${ASSET_SIZE_LIMIT}mb won't be uploaded`,
          SeverityType.Error
        );
      } else {
        const processedFiles = await handleFileProcessing(e.target.files);
        setAssetData((prevItems: any) => [...prevItems, ...processedFiles]);
        setOpenAssetModal(true);
      }
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCloseAssetModal = () => {
    setAssetData([]);
    setOpenAssetModal(false);
  };

  const handleSortModeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newSortMode: string | null
  ) => {
    if (newSortMode !== null) {
      setSortMode(newSortMode);
      handleSort();
    }
  };

  const handleSortOrderByChange = (
    event?: SelectChangeEvent<"date" | "title">
  ) => {
    setSortOrderBy(event?.target.value as "date" | "title");
    handleSort();
  };

  const changeView = (
    event: React.ChangeEvent<any>,
    viewMode: SetStateAction<"grid" | "list">
  ) => {
    if (viewMode !== null) {
      setViewMode(viewMode);
    }
  };

  const handleSort = () => {
    const sortedAssets = (
      searchQuery.length > 0 ? searchedAssets : sampleAssets
    ).sort((a, b) => {
      if (sortOrderBy === "date") {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return sortMode === "up" ? dateA - dateB : dateB - dateA;
      } else if (sortOrderBy === "title") {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) return sortMode === "up" ? -1 : 1;
        if (titleA > titleB) return sortMode === "up" ? 1 : -1;
        return 0;
      }
      return 0;
    });
    if (searchQuery || searchQuery !== "") {
      setSearchedAssets(sampleAssets);
      return;
    }
    setSearchedAssets([]);
    setSampleAssets(sampleAssets);
  };

  const handleFilterChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedFilters(event.target.value as string[]);
  };

  const handleFilterRemove = (filter: string) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((f) => f !== filter)
    );
  };

  const handleSelectAsset = (id: string) => {
    setSelectedAssets((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((assetId) => assetId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectedAssets(
      sampleAssets.length === selectedAssets.length
        ? []
        : sampleAssets.map((asset) => asset.assetPublicId)
    );
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteSingle = async () => {
    try {
      setIsProcessing(true);
      const res = await deleteSingleAsset(
        selectedApplication.uuid,
        selectedPublicId
      );
      if (res.status === 200) {
        setSampleAssets((prevAssets) =>
          prevAssets.filter((asset) => asset.assetPublicId !== selectedPublicId)
        );
        setOpenDeleteModal(false);
        setDeletionFailed(false);
        hideSnackbar(false, "Asset deleted successfully", SeverityType.Success);
      } else {
        setIsProcessing(false);
        setDeletionFailed(true);
        setOpenDeleteModal(false);
        hideSnackbar(
          false,
          "Deletion failed due to some issue",
          SeverityType.Error
        );
      }
    } catch (e) {
      setIsProcessing(false);
      setDeletionFailed(true);
      hideSnackbar(
        false,
        `Deletion failed due to some issue`,
        SeverityType.Error
      );
    }
  };
  const confirmDeleteAsset = (uuid: string) => {
    setSelectedPublicId(uuid);
    setOpenDeleteModal(true);
  };
  const createDuplicate = (uuid: string) => {
    setIsLoading(true);
    duplicateAsset(uuid)
      .then((res) => {
        if (res.status === 200) {
          hideSnackbar(
            false,
            "Asset duplicated successfully",
            SeverityType.Success
          );
          handleUpdateAssetList();
          setIsLoading(false);
        } else {
          hideSnackbar(
            false,
            "Duplicating asset failed due to some issue",
            SeverityType.Error
          );
          setIsLoading(false);
        }
      })
      .catch((e) => {
        hideSnackbar(
          false,
          "Duplicating asset failed due to some issue",
          SeverityType.Error
        );
        setIsLoading(false);
      });
  };
  const deleteMultiple = async () => {
    try {
      setIsProcessing(true);
      if (!selectedAssets.length) {
        hideSnackbar(false, "Please select article", SeverityType.Error);
      }
      const res = await deleteMultipleAssets(selectedApplication.uuid, {
        assetPublicIds: selectedAssets,
      });
      if (res.status === 200 && res.data.acknowledged) {
        setSampleAssets((prevAssets) =>
          prevAssets.filter(
            (asset) => !selectedAssets.includes(asset.assetPublicId)
          )
        );
        hideSnackbar(
          false,
          `${res.data.deletedCount} Assets deleted successfully`,
          SeverityType.Success
        );
        setDeletionFailed(false);
        setOpenDeleteModal(false);
        setSelectedMultiDelete(false);
      } else {
        setIsProcessing(false);
        hideSnackbar(
          false,
          `Deletion failed due to some issue`,
          SeverityType.Error
        );
        setDeletionFailed(true);
      }
    } catch (e) {
      setIsProcessing(false);
      hideSnackbar(
        false,
        `Deletion failed due to some issue`,
        SeverityType.Error
      );
      setDeletionFailed(true);
    }
  };

  const confirmDeleteMultipleAssets = () => {
    setSelectedMultiDelete(true);
    setOpenDeleteModal(true);
  };

  const hideSnackbar = (
    hide: boolean = true,
    message: string = "",
    severity: SeverityType.Success | SeverityType.Error = SeverityType.Success
  ) => {
    setSnackbarState({
      open: !hide,
      message: message,
      severity: severity,
    });
  };

  const handleCloseSnackbar = () => {
    hideSnackbar();
  };

  useEffect(() => {
    (async () => {
      const assets = await fetchData();
      if (assets) {
        setSampleAssets(assets);
        return;
      }
    })();
  }, [selectedApplication.uuid]);

  const fetchData = async () => {
    try {
      const { assets } = await fetchAssets(selectedApplication.uuid, {
        searchTerms: [searchQuery],
      });
      if (assets) {
        return transformAssetData(assets);
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  useEffect(() => {
    if (searchQuery.length === 0) {
      setIsLoading(false);
      setSearchedAssets([]);
      return;
    }
    setIsLoading(true);
    let timeoutId = setTimeout(() => {
      handleSearchAsset();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const transformAssetData = (assets: any[]) => {
    let transformed: IAssetItem[] = assets.map((asset: any) => {
      const uploadIndex =
        asset?.eager?.thumbnail?.secure_url.indexOf("/upload/") +
        ASSET_UPLOAD_INDEX;
      const newSrc =
        asset?.eager?.thumbnail?.secure_url.slice(0, uploadIndex) +
        "a_" +
        asset?.rotate +
        "/" +
        asset?.eager?.thumbnail?.secure_url.slice(uploadIndex);
      return {
        id: asset.uuid,
        assetPublicId: asset.assetPublicId,
        thumbnail: newSrc,
        title: asset.caption,
        url: asset.externalResourceUrl,
        resolution: `${asset.width}x${asset.height}`,
        duration: asset.resource_type === "video" ? asset.duration : "-",
        tags: asset.tags.join(","),
        date: formatDate(asset.createdAt),
        type: asset.resource_type,
        fileSize: bytesToMB(asset.fileSizeInBytes),
        lastModifiedDate: formatDate(asset.updatedAt),
        alt: asset.alt,
        percentCompleted: asset.percentCompleted,
        rotate: asset?.rotate,
        mirror: asset?.mirror,
      };
    });
    return transformed;
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const assetCount =
      searchQuery.length > 0 ? searchedAssets.length : sampleAssets.length;
    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      const count = (prevCount: number) =>
        Math.min(prevCount + NEW_ITEMS_TO_LOAD, assetCount);
      setVisibleAssetsCount(count);
    }
  };
  useEffect(() => {
    if (sampleAssets.length > 0) {
      handleSort();
    }
  }, [sortMode, sortOrderBy, sampleAssets]);

  const handleSearchAsset = async () => {
    if (!searchQuery || searchQuery === "") {
      setSearchedAssets([]);
      return;
    }
    let filteredAssets: IAssetItem[] = [];

    filteredAssets = (await fetchData()) as IAssetItem[];

    if (!filteredAssets || filteredAssets.length === 0) {
      filteredAssets = sampleAssets.filter((asset) => {
        return (
          asset.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          asset.tags.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }
    setSearchedAssets(filteredAssets);
    setIsLoading(false);
  };

  const handleUpdateAssetList = async () => {
    const updatedAssets = await fetchData();
    if (updatedAssets) {
      setSampleAssets(updatedAssets);
    }
  };

  const handleDisplayAssets = () => {
    const assetList =
      searchQuery.length > 0
        ? searchedAssets.length > 0
          ? searchedAssets
          : []
        : sampleAssets && sampleAssets.length > 0
        ? sampleAssets
        : [];
    if (!assetList || assetList.length === 0) {
      return <>No results</>;
    }
    const assetCount = searchQuery ? searchedAssets.length : visibleAssetsCount;

    return [...assetList].slice(0, assetCount).map((asset) => {
      return (
        <AssetDataRenderer
          key={asset.id}
          asset={asset}
          viewMode={viewMode}
          isSelected={selectedAssets.includes(asset.assetPublicId)}
          onSelect={handleSelectAsset}
          onDelete={confirmDeleteAsset}
          onDuplicate={createDuplicate}
          handleUpdateAssetList={handleUpdateAssetList}
        />
      );
    });
  };

  return (
    <div className={styles["assets"]}>
      <AssetUploadModal
        selectedAssetData={assetData}
        openAssetModal={openAssetModal}
        handleCloseAssetModal={handleCloseAssetModal}
        handleUpdateAssetList={handleUpdateAssetList}
      />

      <div className={styles["header-bar"]}>
        <div className={styles["top-bar"]}>
          <div className={styles["title"]}>Asset Library</div>
          {userPermissions?.includes("ArticleCollections.CreateArticle") && (
            <Button
              variant="contained"
              component="label"
              color="primary"
              size="medium"
              tabIndex={-1}
            >
              Upload
              <FileUpload />
              <input
                ref={fileInputRef}
                className={styles.hiddenInput}
                type="file"
                onChange={(e) => handleUploadAssetButtonClick(e)}
                multiple={true}
                accept="image/*, video/*"
              />
            </Button>
          )}
        </div>

        <div className={styles["search-bar"]}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search term..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            label="Search"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <Select
            disabled={isLoading}
            multiple
            displayEmpty
            value={selectedFilters}
            size="small"
            onChange={handleFilterChange}
            input={<OutlinedInput />}
            renderValue={() => "Add Filter"}
            style={{ marginLeft: "10px", width: "200px" }}
            className={customMuiLayer["MuiSelect-root"]}
          >
            {filterOptions.map((filter) => (
              <MenuItem key={filter} value={filter}>
                <Checkbox
                  checked={selectedFilters.includes(filter)}
                  color="primary"
                />
                <ListItemText primary={filter} />
              </MenuItem>
            ))}
          </Select>

          <div className={styles["filters"]}>
            {selectedFilters.map((filter) => (
              <Button
                key={filter}
                variant="outlined"
                size="medium"
                endIcon={<Cancel />}
                onClick={() => handleFilterRemove(filter)}
                style={{ margin: "5px" }}
              >
                {filter}
              </Button>
            ))}
          </div>
        </div>

        <div className={styles["view-bar"]}>
          <div className={styles["view-type"]}>
            <div>
              <span className={styles["view-text"]}>View:</span>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={changeView}
                size="small"
              >
                <ToggleButton value="grid">
                  <ViewModule />
                </ToggleButton>
                <ToggleButton value="list">
                  <ViewList />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <span className={styles["asset-count"]}>
              {searchQuery ? searchedAssets.length : sampleAssets.length} Assets
            </span>
          </div>
          <div className={styles["sort-select"]}>
            <FormControl variant="outlined">
              <InputLabel id="sort-label">Sort By</InputLabel>
              <Select
                disabled={isLoading}
                labelId="sort-label"
                value={sortOrderBy}
                onChange={handleSortOrderByChange}
                label="Sort By"
              >
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="title">Title</MenuItem>
              </Select>
            </FormControl>
            <div className={styles["sort-order"]}>
              Order:
              <ToggleButtonGroup
                disabled={isLoading}
                value={sortMode}
                size="small"
                exclusive
                onChange={handleSortModeChange}
                aria-label="sort mode"
              >
                <ToggleButton value="up" aria-label="sort ascending">
                  <ArrowUpward />
                </ToggleButton>
                <ToggleButton value="down" aria-label="sort descending">
                  <ArrowDownward />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>
      </div>

      <div className={styles["asset-view"]} onScroll={handleScroll}>
        {isLoading ? (
          <>
            <CircularProgress className={styles["loader"]} />
          </>
        ): ''}
        { 
          handleDisplayAssets()
        }
      </div>
      <div className={styles["footer-bar"]}>
        <div>
          <div className={styles["select-group"]}>
            <Checkbox
              checked={
                selectedAssets.length ===
                (searchedAssets.length || sampleAssets.length)
              }
              onChange={handleSelectAll}
              color="primary"
            />
            <span>
              {selectedAssets.length === 0 ? (
                <span>Select all</span>
              ) : selectedAssets.length ===
                (sampleAssets.length || searchedAssets.length) ? (
                <span>Deselect all</span>
              ) : (
                <span className={styles["selected-items-count"]}>
                  {selectedAssets.length} of{" "}
                  {sampleAssets.length || searchedAssets.length} items selected
                </span>
              )}
            </span>
          </div>
        </div>
        <div>
          <Button variant="contained" size="medium" endIcon={<LocalOffer />}>
            Tag
          </Button>
          <Button
            variant="contained"
            size="medium"
            endIcon={<Delete />}
            disabled={selectedAssets.length === 0}
            onClick={() => confirmDeleteMultipleAssets()}
          >
            Delete
          </Button>
        </div>
      </div>
      <CustomModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title={`Delete ${selectedMultiDelete ? "selected Asset's" : "Asset"}`}
        content={`Are you sure that you want to delete ${
          selectedMultiDelete ? "the selected assets" : "the asset"
        } from the system?`}
        onSave={selectedMultiDelete ? deleteMultiple : deleteSingle}
        confirmText={deletionFailed ? "Try Again" : "Yes"}
        cancelText="No"
        isPositiveButton={false}
        isProcessing={isProcessing}
      />
      <Snackbar
        open={snackbarState.open}
        message={snackbarState.message}
        severity={snackbarState.severity}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default Assets;
