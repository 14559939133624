import { Button, Grid, Typography } from "@mui/material";
import styles from "./CustomModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

interface CustomModalHeaderProps {
  headerIcon?: JSX.Element;
  headerText?: string;
  handleClose?: () => void;
  hasBorder?: boolean;
  hasPadding?: boolean;
}

export const ModalHeader = ({
  headerIcon,
  headerText = "Upload Assets",
  handleClose,
  hasBorder = false,
  hasPadding = true,
}: CustomModalHeaderProps) => {
  return (
    <>
      <Grid
        container
        className={`${styles.modalHeaderContainer} ${
          hasBorder ? styles.hasBorderHeader : ""
        } ${hasPadding ? styles.noPadding : ""}`}
      >
        <Grid item xs={9} className={styles.modalHeaderTextContainer}>
          {headerIcon}
          <Typography variant="h6">{headerText}</Typography>
        </Grid>
        <Grid item xs={3} className={styles.modalHeaderButtonContainer}>
          <Button
            onClick={handleClose}
            className={styles.modalHeaderCloseButton}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
