import React, { useRef, useState, useEffect } from "react";
import logger from "../../../utils/logger";
import {
  CircularProgress,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ThemeProvider,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
} from "@mui/material";

import {
  Close as CloseIcon,
  Edit as EditIcon,
  Delete as DeleteOutlineIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  HorizontalSplitOutlined as HorizontalSplitOutlinedIcon,
  AccountTreeOutlined as AccountTreeOutlinedIcon,
  FileUploadOutlined as FileUploadOutlinedIcon,
  FileDownloadOutlined as FileDownloadOutlinedIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { GridCloseIcon } from "@mui/x-data-grid";

import TagSelector from "../../ArticleComponents/TagSelector/TagSelector";
import ArticleVITSelector from "../../ArticleComponents/VITSelector";
import WYSIWYG from "../../ArticleComponents/WYSIWYG/WYSIWYG";
import ImageSelector from "../../ArticleComponents/ImageSelector";
import VideoSelector from "../../ArticleComponents/VideoSelector";

import { useAppSelector, useComponentRefs } from "../../../utils/hooks";
import { ArticleComponentType } from "../../../utils/constants/constants";
import {
  saveArticle,
  getArticle,
  updateArticle,
} from "../../../utils/api/article";
import {
  IArticle,
  IBody,
  ICurrentVersionResponse,
} from "../../../utils/interfaces/article";
import { lightTheme } from "../../../utils/theme/theme";

import styles from "./AddEditArticle.module.scss";
import ArticleRenderer from "../ArticleRenderer/ArticleRenderer";
import CustomModal from "../../CustomModal";
import { formatDate } from "../../../utils/generic";

interface AddEditArticleProps {
  uuid?: string | null | undefined;
  onClose: () => void;
  onArticleChange: () => void;
}

interface SortArticleProps {
  updatedAt: string | number | Date;
}

// TODO: languages array-this will be dynamicall updated
const languages = [
  { code: "en", name: "English" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
];

const AddEditArticle: React.FC<AddEditArticleProps> = ({
  uuid,
  onClose,
  onArticleChange,
}) => {
  const { selectedEdition } = useAppSelector((state) => state.edition);
  const authUser = useAppSelector((state) => state.auth);
  const [updateCount, setUpdateCount] = useState(0);
  const [language, setLanguage] = useState("");

  const [selectedComponents, setSelectedComponents] = useState<
    { component: JSX.Element | null; key: string }[]
  >([]);
  const { useAddComponentRef, useGetComponentRef } = useComponentRefs();

  const vitSelectorRef = useRef<any>(null);
  const tagSelectorRef = useRef<any>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const imageSelectorRef = useRef<any>(null);
  const [imageSelectorData, setImageSelectorData] = useState<{
    value: string | null;
  }>({ value: null });
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [selectedMetaTag, setSelectedMetaTag] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCatalogueTag, setSelectedCatalogueTag] = useState<
    number | null
  >(null);
  const [initialSelectedTags, setInitialSelectedTags] = useState<number[]>([]);
  const [initialSelectedTitle, setInitialSelectedTitle] = useState(null);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false);
  const [changeLogs, setChangeLogs] = useState([]);

  const isMounted = useRef(false);

  useEffect(() => {
    if (textFieldRef.current && initialSelectedTitle !== null) {
      textFieldRef.current.value = initialSelectedTitle;
    }
  }, [initialSelectedTitle]);

  useEffect(() => {
    const fetchArticleData = async () => {
      if (uuid && !isMounted.current) {
        isMounted.current = true;
        setLoading(true);
        try {
          const article = await getArticle(uuid);
          if (article) {
            const currentArticle =
              article.draftVersion || article.currentVersion;
            const sortedPreviousVersions =
              article.previousVersions.length &&
              article.previousVersions.sort(
                (a: SortArticleProps, b: SortArticleProps) =>
                  new Date(b.updatedAt).getTime() -
                  new Date(a.updatedAt).getTime()
              );
            setChangeLogs(sortedPreviousVersions ?? []);
            setSelectedMetaTag(Number(currentArticle.metaTags.rating));
            setInitialSelectedTags([
              currentArticle.metaTags.rating,
              Number(currentArticle.catalogueTags[0]),
            ]);
            setSelectedCatalogueTag(Number(currentArticle.catalogueTags[0]));
            setInitialSelectedTitle(currentArticle.title);
            setTitle(currentArticle.title);
            setImageSelectorData({ value: currentArticle.icon });
            currentArticle.body.forEach(
              (componentData: IBody, index: number) => {
                HandleAddComponent(componentData.type, componentData);
              }
            );
            setLoading(false);
          }
        } catch (error: any) {
          logger("error", error.message);
        }
      }
    };
    fetchArticleData();
  }, [uuid]);

  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current!.value = title;
    }
  }, [loading]);

  const onComponentDataUpdate = () => {
    setUpdateCount((prev) => prev + 1);
  };

  const handleLanguageChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setLanguage(event.target.value);
  };

  const handleSave = async (
    selectedComponents: { component: JSX.Element | null; key: string }[],
    getComponentRef: (key: string) => React.MutableRefObject<any> | undefined,
    type: string
  ) => {
    const tagData = tagSelectorRef.current.getData();
    setIsSaveClicked(true);

    const componentData: any[] = [];
    selectedComponents.forEach(({ key }) => {
      const ref = getComponentRef(key);
      if (ref && ref.current) {
        const data = ref.current.getData();
        componentData.push(data);
      }
    });

    const imageData =
      imageSelectorRef.current?.getData().value || "https://icon.com/11233";

    const articleData: IArticle = {
      method: type === "draft" ? type : "save",
      icon: imageData,
      title: textFieldRef.current?.value || "",
      subtitle: "",
      type: "article",
      description: "",
      metaTags: {
        rating: Number(tagData.data[0]),
      },
      catalogueTags: [Number(tagData.data[1])],
      body: componentData,
      user: authUser.email,
    };

    try {
      uuid
        ? await updateArticle(articleData, uuid)
        : await saveArticle(articleData, selectedEdition.uuid);
      // Integrate global confirmation popup here. So keeping console for time being
      onArticleChange();
      onClose();
    } catch (error: any) {
      // Integrate global confirmation popup here. So keeping console for time being
      console.error("Error creating/updating article:", error.message);
    }
  };

  const HandleAddComponent = (type: string, data?: any) => {
    const { key, ref } = useAddComponentRef();

    setSelectedComponents((prevSelectedComponents) => {
      const component: JSX.Element | null = renderComponent(type, ref, data);
      return [...prevSelectedComponents, { component, key }];
    });
  };

  const handleSaveAsDraftWrapper = () =>
    handleSave(selectedComponents, useGetComponentRef, "draft");
  const handleSaveAsPublishWrapper = () =>
    handleSave(selectedComponents, useGetComponentRef, "save");

  const tagSets = [
    {
      label: "Meta tags",
      tags: [
        { tagId: 1, tagText: "Unrestricted" },
        { tagId: 2, tagText: "Confidential" },
        { tagId: 3, tagText: "Restricted" },
      ],
      selectedTagId: selectedMetaTag,
      onSelect: setSelectedMetaTag,
    },
    {
      label: "Catalogue tags",
      tags: [
        { tagId: 4, tagText: "High" },
        { tagId: 5, tagText: "Medium" },
        { tagId: 6, tagText: "Low" },
      ],
      selectedTagId: selectedCatalogueTag,
      onSelect: setSelectedCatalogueTag,
    },
  ];

  const moveComponentUp = (key: string) => {
    const index = selectedComponents.findIndex((comp) => comp.key === key);
    if (index > 0) {
      const updatedComponents = [...selectedComponents];
      [updatedComponents[index], updatedComponents[index - 1]] = [
        updatedComponents[index - 1],
        updatedComponents[index],
      ];
      setSelectedComponents(updatedComponents);
    }
  };

  const moveComponentDown = (key: string) => {
    const index = selectedComponents.findIndex((comp) => comp.key === key);
    if (index < selectedComponents.length - 1) {
      const updatedComponents = [...selectedComponents];
      [updatedComponents[index], updatedComponents[index + 1]] = [
        updatedComponents[index + 1],
        updatedComponents[index],
      ];
      setSelectedComponents(updatedComponents);
    }
  };

  const removeComponent = (keyToRemove: string) => {
    const updatedComponents = selectedComponents.filter(
      ({ key }) => key !== keyToRemove
    );
    setSelectedComponents(updatedComponents);
  };

  const renderComponent = (
    type: string,
    ref: React.MutableRefObject<any>,
    data: any
  ): JSX.Element | null => {
    switch (type) {
      case ArticleComponentType.TEXT:
        return (
          <WYSIWYG ref={ref} data={data} onChange={onComponentDataUpdate} />
        );
      case ArticleComponentType.VIDEO:
        return (
          <VideoSelector
            ref={ref}
            data={data}
            onChange={onComponentDataUpdate}
          />
        );
      case ArticleComponentType.IMAGE:
        return (
          <ImageSelector
            ref={ref}
            data={data}
            onChange={onComponentDataUpdate}
            isBody={true}
          />
        );
      default:
        return null;
    }
  };

  const handleCancel = () => {
    setConfirmationOpen(true);
  };

  const handleCancelConfirmed = () => {
    setConfirmationOpen(false);
    onClose();
  };

  const handleCancelCancelled = () => {
    setConfirmationOpen(false);
  };

  const handleOpenChangeLogModal = () => {
    setOpenChangeLogModal(true);
  };

  const handleCloseChangeLogModal = () => {
    setOpenChangeLogModal(false);
  };

  const getTimeZone = (date: string) => {
    const time = formatDate(date, "HH:HH").split("+")[0];
    const locale = new Intl.Locale(navigator.language);
    const zone = new Date(date)
      .toLocaleDateString(locale.baseName, {
        day: "2-digit",
        timeZoneName: "short",
      })
      .slice(4);
    return `${time} ${zone}`;
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <div className={styles["add-edit-article-overlay"]}>
        <div className={styles["add-edit-article-container"]}>
          <div className={styles["title-bar"]}>
            <div>
              <IconButton color="primary" size="small">
                <EditIcon />
              </IconButton>
              Article Editor
            </div>
            <IconButton color="primary" size="small" onClick={handleCancel}>
              <GridCloseIcon />
            </IconButton>
          </div>
          <div className={styles["header-bar"]}>
            <div className={styles["left-menu"]}>
              {changeLogs.length > 0 && (
                <Button
                  variant="text"
                  color="primary"
                  endIcon={<HorizontalSplitOutlinedIcon />}
                  onClick={handleOpenChangeLogModal}
                >
                  Changelog
                </Button>
              )}
              <Button
                variant="text"
                color="primary"
                endIcon={<AccountTreeOutlinedIcon />}
              >
                Editions
              </Button>
            </div>
            <div className={styles["right-menu"]}>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<FileUploadOutlinedIcon />}
              >
                Import
              </Button>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<FileDownloadOutlinedIcon />}
              >
                Export
              </Button>
            </div>
          </div>
          {!loading ? (
            <>
              <div className={styles["tag-selector-component"]}>
                <TagSelector
                  tagSets={tagSets}
                  initialSelectedTags={initialSelectedTags}
                  ref={tagSelectorRef}
                />
              </div>
              <div className={styles["add-edit-article-pane"]}>
                <div>
                  <div className={styles["header"]}>
                    Edit
                    <FormControl variant="outlined" className={styles["form"]}>
                      <InputLabel id="language-select-label">
                        Language
                      </InputLabel>
                      <Select
                        labelId="language-select-label"
                        value={language}
                        onChange={handleLanguageChange}
                        label="Language"
                        variant="outlined"
                        size="small"
                      >
                        {languages.map((lang) => (
                          <MenuItem key={lang.code} value={lang.code}>
                            {lang.name} ({lang.code.toUpperCase()})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles["common-components"]}>
                    <TextField
                      label="Headline"
                      fullWidth
                      inputRef={textFieldRef}
                      onChange={onComponentDataUpdate}
                    />
                    <div className={styles["title-image-component"]}>
                      <ImageSelector
                        ref={imageSelectorRef}
                        data={imageSelectorData}
                        onChange={onComponentDataUpdate}
                        isBody={false}
                      />
                    </div>
                  </div>
                  {selectedComponents.length > 0 && (
                    <div className={styles["selected-components"]}>
                      {selectedComponents.map(({ component, key }, index) => (
                        <div key={key}>
                          {component}
                          <div className={styles["helper-buttons"]}>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => moveComponentUp(key)}
                              disabled={index === 0}
                            >
                              <ArrowUpwardIcon />
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => removeComponent(key)}
                            >
                              <DeleteOutlineIcon />
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => moveComponentDown(key)}
                              disabled={index === selectedComponents.length - 1}
                            >
                              <ArrowDownwardIcon />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className={styles["article-vit-selector"]}>
                    <div className={styles["header"]}>Add</div>
                    <ArticleVITSelector
                      ref={vitSelectorRef}
                      onSelect={(type: string) => HandleAddComponent(type)}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles["header"]}>
                    Preview
                    <FormControl variant="outlined" className={styles["form"]}>
                      <InputLabel id="language-select-label">
                        Language
                      </InputLabel>
                      <Select
                        labelId="language-select-label"
                        value={language}
                        onChange={handleLanguageChange}
                        label="Language"
                        variant="outlined"
                        size="small"
                      >
                        {languages.map((lang) => (
                          <MenuItem key={lang.code} value={lang.code}>
                            {lang.name} ({lang.code.toUpperCase()})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles["preview-pane"]}>
                    <ArticleRenderer
                      updateCount={updateCount}
                      components={selectedComponents}
                      getComponentRef={useGetComponentRef}
                      textFieldRef={textFieldRef}
                      imageSelectorRef={imageSelectorRef}
                    />
                  </div>
                </div>
              </div>
              <div className={styles["add-edit-article-action-buttons"]}>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  startIcon={<CloseIcon />}
                >
                  Discard
                </Button>
                <div>
                  <Button
                    variant="contained"
                    disabled={isSaveClicked}
                    color="primary"
                    onClick={handleSaveAsDraftWrapper}
                  >
                    Save as draft
                  </Button>
                  <Button
                    variant="contained"
                    disabled={isSaveClicked}
                    color="primary"
                    onClick={handleSaveAsPublishWrapper}
                  >
                    Request publishing
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.customLoading}>
              <CircularProgress />
            </div>
          )}

          <Dialog open={confirmationOpen} onClose={handleCancelCancelled}>
            <DialogTitle>Confirm Cancel</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to cancel? Updates will be lost.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelConfirmed} color="error" autoFocus>
                Yes, Cancel
              </Button>
              <Button onClick={handleCancelCancelled} color="primary">
                No
              </Button>
            </DialogActions>
          </Dialog>
          <CustomModal
            open={openChangeLogModal}
            onClose={handleCloseChangeLogModal}
            onSave={handleCloseChangeLogModal}
            title="Changelog"
            confirmText="OKAY"
            content=""
            ignoreCancelButton={true}
            saveIcon={<CheckIcon />}
            headerIcon={<HorizontalSplitOutlinedIcon />}
            hasBorder={true}
            hasPadding={false}
            isConfirmationButtonRight={true}
          >
            <>
              <div className={styles.tableLogContainer}>
                <p>This article was undergoing the following changes:</p>
                <TableContainer className={styles.tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow className={styles.changeLogRow}>
                        <TableCell>Creation Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {changeLogs.length > 0 &&
                        changeLogs.map(
                          ({
                            uuid,
                            updatedAt,
                            user,
                            status,
                          }: ICurrentVersionResponse) => {
                            return (
                              <TableRow
                                key={uuid}
                                className={styles.changeLogRow}
                              >
                                <TableCell>
                                  {formatDate(updatedAt, "dd-MMM-yyyy")}
                                </TableCell>
                                <TableCell>{getTimeZone(updatedAt)}</TableCell>
                                <TableCell>
                                  <Tooltip title={user && user} placement="top">
                                    <span className={styles.userCell}>
                                      {user && user}
                                    </span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell className={styles.status}>
                                  {status}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </>
          </CustomModal>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AddEditArticle;
