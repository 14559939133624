import { FC, useEffect, useState } from "react";
import { useAccessToken } from "../../utils/helpers/token";
import { actions } from '../../redux/slice/app.slice';
import { useAppDispatch } from '../../utils/hooks';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useMsal } from "@azure/msal-react";
import Application from "../../components/App/App";
import Header from '../../components/Header/Header';
import Footer from "../../components/Footer/Footer";

import {
  fetchRoles } from '../../redux/slice/roles.slice';
type Props = {
  setTokens: any;
  setUserDetails: any;
  getAllApplications: any;
};

const App: FC<Props> = ({
  setTokens,
  setUserDetails,
  getAllApplications,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { accounts } = useMsal();
  const { tracking } = actions;
  const dispatch = useAppDispatch();
  const appInsights = useAppInsightsContext();
  const token = useAccessToken();
  useEffect(() => {    
    (async () => {
      setIsLoading(true);
      if (token) {
        const user = await setTokens({
          accessToken: token,
          refreshToken: "tbc"
        });
        dispatch(
          tracking({
            appInsights,
            name: 'debug-user',
            payload: user ? { type: 'setTokens', data: user } : { type: 'no setTokens' },
          }),
        );
        if (accounts.length > 0 && accounts[0].username !== undefined) {
          const userDetail = await setUserDetails({
            email: accounts[0].username,
            firstName: accounts[0].name?.split(",")[1].split(" ")[1],
            lastName: accounts[0].name?.split(",")[0],
          });
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: userDetail ? { type: 'setUserDetails', data: userDetail } : { type: 'no setUserDetails' },
            }),
          );
          const appList = await getAllApplications();
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: appList ? { type: 'getAllApplications', data: appList } : { type: 'no getAllApplications' },
            }),
          )
          dispatch(fetchRoles());
        }
        setIsLoading(false);
      }
    })();
  }, [token]);

  return (
    <>
      <Header />
      <Application isLoading={isLoading} />      
      <Footer />
    </>
  );
};

export default App;
