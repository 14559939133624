import React, { useState, useEffect, useImperativeHandle, useRef, forwardRef } from 'react';
import { Autocomplete, TextField, Switch } from '@mui/material';
import styles from './VideoSelector.module.scss';
import { IArticleComponentData, IAttributes } from '../../../utils/interfaces/article';

interface Video {
    name: string;
    url: string;
    attributes: IAttributes;
}

interface VideoSelectorProps {
    data?: {
        value: string | null;
        attributes: IAttributes;
    };
    onChange?: (data: IArticleComponentData) => void;
}

export interface VideoSelectorRef {
    getData: () => IArticleComponentData;
}

const VideoSelector: React.ForwardRefRenderFunction<VideoSelectorRef, VideoSelectorProps> = ({ data, onChange }, ref) => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
    const dataRef = useRef<IArticleComponentData>({
        type: 'VIDEO',
        value: "",
        meta: {},
        attributes: {}
    });

    useEffect(() => {
        const generateVideoData = () => {
            const sampleVideos: Video[] = [
                { name: 'Video 1', url: 'https://www.w3schools.com/html/mov_bbb.mp4', attributes: { alt: '', controls: false, autoplay: false, loop: false, muted: false, poster: '' } },
                { name: 'Video 2', url: 'https://www.w3schools.com/html/movie.mp4', attributes: { alt: '', controls: false, autoplay: false, loop: false, muted: false, poster: '' } },
                { name: 'Video 3', url: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4', attributes: { alt: '', controls: false, autoplay: false, loop: false, muted: false, poster: '' } }
            ];
            setVideos(sampleVideos);
        };

        generateVideoData();
    }, []);

    useEffect(() => {
        if (data && data.value !== null) {
            const selected = videos.find(video => video.url === data.value);
            setSelectedVideo(selected || null);
            dataRef.current.value = data.value;
            dataRef.current.attributes = data.attributes || {};
        }
    }, [data, videos]);

    useEffect(() => {
        if (onChange) {
            onChange(getData());
        }
    }, [selectedVideo, onChange]);

    const getData = (): IArticleComponentData => {
        return {
            ...dataRef.current,
            value: selectedVideo?.url || null
        };
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    const handleVideoSelect = (event: React.ChangeEvent<{}>, value: Video | null) => {
        setSelectedVideo(value);
        dataRef.current.value = value ? value.url : null;
        dataRef.current.attributes = value ? value.attributes : {};
    };

    const handleSelectedVideo = (key: string, value?: boolean | string) => {
        if (selectedVideo) {
            const updatedValue = typeof value === 'boolean' ? value : (value?.length ? value : '');
            const updatedSelectedVideo = { ...selectedVideo, attributes: { ...selectedVideo.attributes, [key]: updatedValue } };
            setSelectedVideo(updatedSelectedVideo);
            dataRef.current.value = updatedSelectedVideo.url;
            dataRef.current.attributes = updatedSelectedVideo.attributes;
        }
    };

    return (
        <div className={styles['video-selector-container']}>
            <div className={styles['selected-video']}>
                {selectedVideo && (
                    <video controls src={selectedVideo.url} width="320" height="240">
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            <div className={styles['video-picker']}>
                <div>
                    <Autocomplete
                        options={videos}
                        getOptionLabel={(option) => option.name}
                        onChange={handleVideoSelect}
                        renderInput={(params) => (
                            <TextField {...params} label="Click inside to pick video" />
                        )}
                    />
                </div>
                <div>
                    <TextField
                        label="File name"
                        value={selectedVideo?.name || ''}
                        InputProps={{ readOnly: true }}
                    />
                </div>
                <div>
                    <TextField
                        label="URL"
                        value={selectedVideo?.url || ''}
                        InputProps={{ readOnly: true }}
                    />
                </div>
                {selectedVideo && (<>
                    <div>
                        <label>Controls:</label>
                        <Switch
                            checked={selectedVideo.attributes.controls}
                            onChange={(e) => handleSelectedVideo('controls', e.target.checked)}
                            inputProps={{ 'aria-label': 'toggle button' }}
                        />
                    </div>
                    <div>
                        <label>Autoplay:</label>
                        <Switch
                            checked={selectedVideo.attributes.autoplay}
                            onChange={(e) => handleSelectedVideo('autoplay', e.target.checked)}
                            inputProps={{ 'aria-label': 'toggle button' }}
                        />
                    </div>
                    <div>
                        <label>Loop:</label>
                        <Switch
                            checked={selectedVideo.attributes.loop}
                            onChange={(e) => handleSelectedVideo('loop', e.target.checked)}
                            inputProps={{ 'aria-label': 'toggle button' }}
                        />
                    </div>
                    <div>
                        <label>Muted:</label>
                        <Switch
                            checked={selectedVideo.attributes.muted}
                            onChange={(e) => handleSelectedVideo('muted', e.target.checked)}
                            inputProps={{ 'aria-label': 'toggle button' }}
                        />
                    </div>
                    <div>
                        <label>Poster URL:</label>
                        <TextField
                            label="Poster URL:"
                            value={selectedVideo.attributes.poster || ''}
                            onChange={(e) => handleSelectedVideo('poster', e.target.value)}
                        />
                    </div>
                </>)}
            </div>
        </div>
    );
};

export default forwardRef(VideoSelector);
