import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  styled,
  IconButton,
  MenuItem,
} from "@mui/material";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import styles from "./ListArticles.module.scss";
import commonStyles from "../../../styles.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatDate, toTitleCase } from "../../../utils/generic";
import { IArticleResponse } from "../../../utils/interfaces/article";
import OptionMenu from "../../OptionMenu/OptionMenu";
import { useAppSelector } from "../../../utils/hooks";
import { ArticleStatus } from "../";

interface ListArticlesProps {
  articleData: IArticleResponse;
  viewMode: "grid" | "list";
  isSelected: boolean;
  onSelect: (id: string) => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
}

const StyledListArticleTitle = styled("div")({
  fontSize: "12px",
  fontWeight: 500,
  color: "#808080",
});

const StyledListArticleDesc = styled("div")({
  fontSize: "14px",
});

const ListArticles: React.FC<ListArticlesProps> = ({
  articleData,
  viewMode,
  isSelected,
  onSelect,
  onEdit,
  onDelete,
}) => {
  const article = articleData.currentVersion;
  const { selectedApplication } = useAppSelector(
    (state: any) => state.application
  );
  const userPermissions = selectedApplication.permissions;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleEditAndClose = (uuid: string) => {
    onEdit(uuid);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {viewMode === "grid" ? (
        <Box>
          <Box
            className={`${styles["item-renderer"]} ${commonStyles["standardBoxShadow"]}`}
          >
            <div className={styles["item-overlay"]}>
              <div className={styles["date-info"]}>
                <Checkbox
                  checked={isSelected}
                  onChange={() => onSelect(articleData.uuid)}
                  color="primary"
                  className={`${styles["select-checkbox"]}`}
                />
                {formatDate(articleData.updatedAt)}
              </div>
              <Box className={styles["background"]}>
                <p title={article.title}>{article.title}</p>
                <div className={styles["language"]}>EN</div>
              </Box>
              <div className={styles["article-info"]}>
                <ArticleStatus status={article.status} />
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon className={styles["content-center"]} />
                </IconButton>
                <OptionMenu
                  anchorEl={anchorEl}
                  open={open}
                  handleClose={handleClose}
                >
                  {userPermissions?.includes(
                    "ArticleCollections.UpdateArticle"
                  ) && (
                    <MenuItem
                      onClick={() => handleEditAndClose(articleData.uuid)}
                    >
                      Edit <BorderColorOutlinedIcon />
                    </MenuItem>
                  )}
                  {userPermissions?.includes(
                    "ArticleCollections.CreateArticle"
                  ) && (
                    <MenuItem>
                      Duplicate <ContentCopyOutlinedIcon />
                    </MenuItem>
                  )}
                  {userPermissions?.includes(
                    "ArticleCollections.ApproveArticle"
                  ) && (
                    <MenuItem>
                      Approve <ThumbUpAltOutlinedIcon />
                    </MenuItem>
                  )}
                  {userPermissions?.includes(
                    "ArticleCollections.DeleteArticle"
                  ) && (
                    <MenuItem onClick={() => onDelete(articleData.uuid)}>
                      Delete <DeleteOutlineOutlinedIcon />
                    </MenuItem>
                  )}
                </OptionMenu>
              </div>
            </div>
          </Box>
        </Box>
      ) : (
        <div className={`${styles["list-item-renderer"]}`}>
          <Grid container className={styles["list-article-info"]}>
            <Grid item xs={0.5} className={styles["content-center"]}>
              <Checkbox
                checked={isSelected}
                onChange={() => onSelect(articleData.uuid)}
                color="primary"
                defaultChecked
                className={`${styles["select-checkbox"]}`}
              />
            </Grid>

            <Grid item xs={6}>
              <StyledListArticleTitle>Title</StyledListArticleTitle>
              <StyledListArticleDesc>{article.title}</StyledListArticleDesc>
            </Grid>
            <Grid item xs={2}>
              <StyledListArticleTitle>Languages</StyledListArticleTitle>
              <StyledListArticleDesc>
                <div className={styles["language"]}>EN</div>
              </StyledListArticleDesc>
            </Grid>
            <Grid item xs={2}>
              <StyledListArticleTitle>Created</StyledListArticleTitle>
              <StyledListArticleDesc>
                {formatDate(articleData.createdAt)}
              </StyledListArticleDesc>
            </Grid>
            <Grid item xs={1}>
              <StyledListArticleTitle>Status</StyledListArticleTitle>
              <StyledListArticleDesc>
                <ArticleStatus status={article.status} />
              </StyledListArticleDesc>
            </Grid>
            <Grid item xs={0.5}>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon className={styles["content-center"]} />
              </IconButton>

              <OptionMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              >
                {userPermissions?.includes(
                  "ArticleCollections.UpdateArticle"
                ) && (
                  <MenuItem
                    onClick={() => handleEditAndClose(articleData.uuid)}
                  >
                    Edit <BorderColorOutlinedIcon />
                  </MenuItem>
                )}
                {userPermissions?.includes(
                  "ArticleCollections.CreateArticle"
                ) && (
                  <MenuItem>
                    Duplicate <ContentCopyOutlinedIcon />
                  </MenuItem>
                )}
                {userPermissions?.includes(
                  "ArticleCollections.ApproveArticle"
                ) && (
                  <MenuItem>
                    Approve <ThumbUpAltOutlinedIcon />
                  </MenuItem>
                )}
                {userPermissions?.includes(
                  "ArticleCollections.DeleteArticle"
                ) && (
                  <MenuItem onClick={() => onDelete(articleData.uuid)}>
                    Delete <DeleteOutlineOutlinedIcon />
                  </MenuItem>
                )}
              </OptionMenu>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ListArticles;
