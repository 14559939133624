import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography, LinearProgress } from "@mui/material";
import styles from "./EditAsset.module.scss";
import TextField from '@mui/material/TextField';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import InfoIcon from '@mui/icons-material/Info';
import MenuItem from '@mui/material/MenuItem';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import { Rotate90DegreesCcw } from "@mui/icons-material";
import { IAssetItem, IAssetUpdateItem } from "../../utils/interfaces/assets";
import OptionMenu from "../OptionMenu/OptionMenu";
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

interface EditAssetProps {
    asset: IAssetItem;
    onFileDataChange: any;
}

const EditAsset = ({
 asset,
 onFileDataChange
}: EditAssetProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const videoRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fileData, setFileData] = useState<IAssetUpdateItem>(
        {
            file: asset.file,
            assetPublicId: asset.assetPublicId,
            caption: asset.title,
            alt: asset.alt,
            tags: asset.tags.split(',') as never[],
            rotate: asset.rotate ? asset.rotate : 90,
            mirror: asset.mirror ? asset.mirror : false,
        }
    );
    const [isMirrored, setIsMirrored] = useState<boolean>(false);
    const [rotationDegree, setRotationDegree] = useState<number>(0);
    const [degVal, setDegVal] = useState<number>(0);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {        
        onFileDataChange(fileData);
    }, [fileData]);

    const handleChangeAsset = async (e: ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement> | any,
        selectedFile?: FileList | File[]) => {
        setAnchorEl(null);
        if (fileInputRef.current?.files) {
            let file = selectedFile && selectedFile.length > 0 ? selectedFile : e.target.files;
            file = file[0];
            const [type] = file.type.split("/");
            if (type === "video") {
                const video = document.createElement("video");
            
                video.src = URL.createObjectURL(file);
                video.preload = "metadata";
                asset.type = "video";
                asset.thumbnail = video.src;
                video.addEventListener("loadedmetadata", async () => {            
                    setFileData({
                        file: file,
                        assetPublicId: '',
                        caption: asset.title,
                        alt: asset.alt,
                        tags: asset.tags.split(',') as never[],
                        rotate: asset.rotate,
                        mirror: asset.mirror
                    });
                });
            } else {
                const img = document.createElement("img");
                img.src = URL.createObjectURL(file);
                asset.type = "image";
                asset.thumbnail = img.src;
                img.addEventListener("load", () => {            
                    setFileData({
                        file: file,
                        assetPublicId: '',
                        caption: asset.title,
                        alt: asset.alt,
                        tags: asset.tags.split(',') as never[],
                        rotate: asset.rotate,
                        mirror: asset.mirror
                    });
                });
            }
        }
    }

    const handleChangeAssetData = (e: any) => {
        switch(e.target.name) {
            case "title":
                setFileData({
                    file: fileData?.file,
                    assetPublicId: '',
                    caption: e.target.value,
                    alt: fileData?.alt,
                    tags: fileData?.tags,
                    rotate: fileData?.rotate,
                    mirror: fileData?.mirror
                });
                break;
            case "alt":
                setFileData({
                    file: fileData?.file,
                    assetPublicId: '',
                    caption: fileData?.caption,
                    alt: e.target.value,
                    tags: fileData?.tags,
                    rotate: fileData?.rotate,
                    mirror: fileData?.mirror
                });
                break;
            case "tags":
                setFileData({
                    file: fileData?.file,
                    assetPublicId: '',
                    caption: fileData?.caption,
                    alt: fileData?.alt,
                    tags: e.target.value.split(',') as never[],
                    rotate: fileData?.rotate,
                    mirror: fileData?.mirror
                });
                break;
            default:
                break;
        }
    };

    const toggleMirror = () => {
        setFileData({
            file: fileData?.file,
            assetPublicId: '',
            caption: fileData?.caption,
            alt: fileData?.alt,
            tags: fileData?.tags,
            rotate: fileData?.rotate,
            mirror: !isMirrored
        });
        setIsMirrored(!isMirrored);
        setAnchorEl(null);
    };
    
    const rotateVideo = (degrees: any) => {
        setFileData({
            file: fileData?.file,
            assetPublicId: '',
            caption: fileData?.caption,
            alt: fileData?.alt,
            tags: fileData?.tags,
            rotate: degVal+degrees,
            mirror: fileData?.mirror
        });
        setDegVal(degVal+degrees);
    };

    useEffect(()=>{
        if(degVal === 360){
            setDegVal(0);
            }
        setRotationDegree(degVal);
        setAnchorEl(null);
    }, [degVal]);

  return (
    <Box className={styles["edit-asset"]}>
        <Box className={styles["background"]}>

        {asset.type.includes("image")
            ? 
            <>
            <img 
            src={asset.thumbnail || asset.url} alt={asset.title}
            className={`${styles.thumbnail} video-element ${isMirrored ? styles.mirrored : ''}`}
                        style={{ transform: `rotate(${rotationDegree}deg)` }} />
            </>
            :
            <>
                    <video 
                        ref={videoRef}
                        className={`video-element ${isMirrored ? styles.mirrored : ''}`}
                        style={{ transform: `rotate(${rotationDegree}deg)` }}
                        muted
                        width="400"
                        controls 
                        autoPlay
                        src={asset.url}>
                        <source src={asset.thumbnail} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
            </>            
        }

            <IconButton aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                className={styles["circle-icon"]}>
                <BuildCircleIcon 
                />
            </IconButton>
            <InfoIcon className={styles["info-icon"]} />

            <OptionMenu
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}>
                <MenuItem
                    onClick={toggleMirror}>
                    Mirror <FlipOutlinedIcon />
                </MenuItem>
                <MenuItem
                    onClick={() => rotateVideo(90)}>
                    Rotate 90° <Rotate90DegreesCcw />
                </MenuItem>
                <MenuItem
                    className={styles.modalButton}
                    component="label"
                >
                  Upload New File <UploadOutlinedIcon />
                  <input
                    ref={fileInputRef}
                    className={styles.hiddenInput}
                    type="file"
                    onChange={(e) => handleChangeAsset(e)}
                    accept="image/*, video/*"
                  />
                </MenuItem>
            </OptionMenu>
        </Box>
        {asset.percentCompleted >= 1 && (
            <Box mt={2} px={2}>
                <Typography variant="body2">
                    Uploading... {asset.percentCompleted || 0}%
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={asset.percentCompleted || 0}
                />
            </Box>
        )}
        <TextField
            id="outlined-basic"
            name="title"
            label="Title"
            size="small"
            defaultValue={asset.title}
            onChange={(e) => handleChangeAssetData(e)}
        />
        <TextField
            id="outlined-multiline-static"
            name= "alt"
            label="Description"
            multiline
            rows={3}
            defaultValue={asset.alt} onChange={(e) => handleChangeAssetData(e)} />
        <TextField
            name ="tags"
            id="outlined-basic"
            label="Tags"
            size="small"
            defaultValue={asset.tags}
            onChange={(e) => handleChangeAssetData(e)}
        />
    </Box>
  );
};

export default EditAsset;
