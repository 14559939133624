import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import TextIcon from '@mui/icons-material/TextFields';
import VideoIcon from '@mui/icons-material/VideoLibrary';
import ImageIcon from '@mui/icons-material/Image';
import {  Tooltip, Button } from '@mui/material';
import { ArticleComponentType } from '../../../utils/constants/constants';

import styles from './VITSelector.module.scss';

interface VITSelectorProps {
    onSelect: (editorType: string) => void;
}

export interface VITSelectorRef {
    getData: () => { a: number; b: string; };
}

const VITSelector: React.ForwardRefRenderFunction<VITSelectorRef, VITSelectorProps> = ({ onSelect }, ref) => {
    const dataRef = useRef<{ a: number, b: string }>({ a: 10, b: 'Hello' })

    const getData = () => {
        dataRef.current.a = Math.floor(Math.random() * 100)
        return dataRef.current;
    };

    useImperativeHandle(ref, () => ({
        getData
    }));

    return (
        <div className={styles['vit-selector']}>
            <div className={styles['vit-option']} onClick={() => onSelect(ArticleComponentType.TEXT)}>
                <Tooltip title="Add Text">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<TextIcon />}
                    >
                        Text
                    </Button>
                </Tooltip>                
            </div>
            <div className={styles['vit-option']} onClick={() => onSelect(ArticleComponentType.IMAGE)}>
                <Tooltip title="Add Image">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ImageIcon />}
                    >
                        Picture
                    </Button>
                </Tooltip>
            </div>
            <div className={styles['vit-option']} onClick={() => onSelect(ArticleComponentType.VIDEO)}>
                <Tooltip title="Add Video">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<VideoIcon />}
                    >
                        Video
                    </Button>
                </Tooltip>
            </div>
        </div>

    );
};

export default forwardRef(VITSelector);
